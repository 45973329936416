body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-header {
  height: 48px;
}
.ant-menu {
  text-align: left;
}

.lessons-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin: 16px;
  background: rgba(13, 9, 9, 0.2);
  color: white;
}

.content-wrapper {
  padding: 24px 56px 77px;
  min-height: 360px;
  background: rgb(255, 255, 255);
  margin-top: 20px;
}

.content-title {
  text-align: center;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 800;
}
.previewImageLink {
  width: 250px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container video {
  width: 95%;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 15px;
  width: 90%;
}

.controls button {
  margin: 0 5px;
  padding: 5px 10px;
}

.soundOff svg path {
  /* color: rgb(255, 0, 4); */
}
.soundOff {
  position: relative;
}

.soundOff::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: black;
  transform: rotateZ(302deg);
  top: 10px;
  right: 0px;
}
.spinner {
  margin: 40vh 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.video-button svg {
  width: 24px;
  height: 24px;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-list .ant-list-pagination {
  margin: 25px 0 40px;
}

.lesson-title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-index {
  margin: 0 8px 0 4px;
}

@media (max-width: 900px) {
  .controls {
    flex-wrap: wrap;
  }
}
@media (max-width: 760px) {
  .video-button svg {
    width: 16px;
    height: 20px;
  }
  .content-wrapper {
    padding: inherit;
  }
  .soundOff::after {
    width: 19px;
    height: 2px;
    top: 8px;
  }
}
@media (max-width: 550px) {
  .video-button svg {
    width: 16px;
    height: 20px;
  }
}
